/* eslint-disable */
import React, { useEffect, useState } from "react";
import style from "./AboutUs.module.css";
import { Row, Col, Accordion, AccordionButton } from "react-bootstrap";
import { Row as AntRow, Col as AntCol, Card, Avatar } from "antd";
import {
  FacebookOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

import {
  TrophyOutlined,
  RocketOutlined,
  UserOutlined,
  SafetyOutlined,
} from "@ant-design/icons";

const headTeams = [
  {
    id: 1,
    name: "Florent Rexhepi",
    position: "Leiter SEO",
    image: "/assets/team7.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 2,
    name: "Reinhard Vilic-Plett",
    position: "CEO",
    image: "/assets/team2_.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 3,
    name: "Ajdin Kirov",
    position: "Vertriebsleiter DACH",
    image: "/assets/team1_.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
];
const teamMembers = [
  {
    id: 4,
    name: "Laura Qela",
    position: "Verwaltungsmanagerin",
    image: "/assets/team3_.png  ",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 5,
    name: "Ylberina Aliu",
    position: "Webanalyse-Managerin",
    image: "/assets/team4.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 6,
    name: "Egzona Maljici",
    position: "E-Mail-Marketing-Managerin",
    image: "/assets/team5.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
];
const additionalTeamMembers = [
  {
    id: 1,
    name: "Ermelind Máté",
    position: "Grafikdesigner",
    image: "/assets/team6_.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 2,
    name: "Ubejd Maxhuni",
    position: "Grafikdesigner",
    image: "/assets/um.jpg",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 3,
    name: "Donika Qela",
    position: "Softwareentwicklerin",
    image: "/assets/donika.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 4,
    name: "Eliza Lazarev",
    position: "Kundenbetreuerin",
    image: "/assets/team11.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },

  {
    id: 5,
    name: "Leo Bytyci",
    position: "Kundenbetreuer",
    image: "/assets/team15.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 6,
    name: "Andrea Piacquadio",
    position: "Kundendienstvertretung",
    image: "/assets/team14.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 7,
    name: "Alexa Carmen",
    position: "Java Entwicklerin",
    image: "/assets/team20.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 8,
    name: "Jorge Torres",
    position: "Php Entwickler",
    image: "/assets/team22.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
  {
    id: 9,
    name: "Kane Vázquez",
    position: "Php Entwickler",
    image: "/assets/team21.png",
    social: {
      facebook: "https://facebook.com",
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
    },
  },
];
const UberUns = () => {
  const [selectedSection, setSelectedSection] = useState("SafeMask ");
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const mainSection = [
    "SafeMask ",
    "Mideo Online ",
    "Marktforschung ",
    "Schutzschild ",
    "Home Deluxe",
    "Juskys ",
    "Econelo ",
    "Ibeko ",
    // "Beneficiaries Management",
  ];

  const AccordionItems = {
    "SafeMask ": [
      {
        title: "SafeMask ",
        body: "Wir bei SafeMask Company sind tief beeindruckt von dem außergewöhnlichen Service, den WKS Company bereitgestellt hat. Ihre Hingabe, erstklassigen Kundensupport zu liefern, hat unsere Abläufe entscheidend verbessert. Die Fähigkeit des WKS-Teams, sich an unsere speziellen Bedürfnisse anzupassen, und ihr unerschütterliches Engagement für Exzellenz haben nicht nur unseren Workflow optimiert, sondern auch die Zufriedenheit unserer Kunden erhöht. Ihre innovativen Lösungen und proaktive Herangehenswei",
      },
    ],
    "Mideo Online ": [
      {
        title: "Deutschland",
        body: (
          <>
            WKS hat uns wirklich beeindruckt, vor allem mit ihrer Kommunikation
            mit unseren Kunden.Ihr proaktiver Ansatz hat nicht nur unsere
            Erwartungen übertroffen, sondern auch unsere Kundentreue gestärkt.
            <br />
            <br />
            Ihr professioneller Umgang mit unseren Kundenanfragen ermöglicht es
            uns, uns auf unser Kerngeschäft zu konzentrieren, während sie sich
            um den Kundenservice kümmern.
          </>
        ),
      },
    ],
    "Marktforschung ": [
      {
        title: "Marktforschung",
        body: (
          <>
            Die Marktanalysen von WKS haben uns wirklich geholfen. Ihre
            Einblicke waren äußerst wertvoll und haben dazu beigetragen, unsere
            Marketingstrategie zu verfeinern.
          </>
        ),
      },
    ],
    "Schutzschild ": [
      {
        title: "Schutzschild",
        body: (
          <>
            WKS hat unsere Erwartungen an einen Kundenservice-Partner wirklich
            übertroffen. Ihre schnelle Reaktionszeit und ihre Fähigkeit,
            Lösungen zu finden, sind wirklich bemerkenswert.
            <br />
            <br />
            Mit WKS haben wir einen Partner gefunden, der die Bedeutung von
            Sicherheit und Kundenservice versteht und diese nahtlos in unsere
            Prozesse integriert.
          </>
        ),
      },
    ],
    "Home Deluxe": [
      {
        title: "Home",
        body: (
          <>
            Die Agenten von WKS haben wirklich gezeigt, dass sie unser
            Produktangebot schätzen und dieses Verständnis in jedem
            Kundenkontakt zum Ausdruck gebracht haben.
            <br />
            <br />
            Durch ihre empathische und sachkundige Herangehensweise hat WKS
            unseren Online-Kundenservice transformiert und dadurch spürbar die
            Kundenzufriedenheit gesteigert.
          </>
        ),
      },
    ],
    "Juskys ": [
      {
        title: "Juskys",
        body: (
          <>
            WKS hat unseren Kundendienst mit ihrer Expertise und ihrem
            Engagement wirklich auf ein neues Level gebracht.
            <br />
            <br />
            Die Zusammenarbeit mit WKS war ein entscheidender Faktor für die
            Verbesserung unseres Kundenengagements und hat uns dabei geholfen,
            positive Kundenbeziehungen aufzubauen.
          </>
        ),
      },
    ],
    "Econelo ": [
      {
        title: "Econelo",
        body: (
          <>
            Dank der Unterstützung von WKS in unserem Kundenservice konnten wir
            eine deutliche Steigerung der Kundenzufriedenheit und -loyalität
            feststellen.
            <br />
            <br />
            WKS hat unsere Kapazitäten im Kundendienst mit ihrer Fachkompetenz
            und ihrem starken Engagement ausgezeichnet erweitert.
          </>
        ),
      },
    ],
    "Ibeko ": [
      {
        title: "Ibeko",
        body: (
          <>
            Die Partnerschaft mit WKS hat es uns ermöglicht, unseren Kundenstamm
            durch erstklassigen Service zu erweitern.
            <br />
            <br />
            WKS hat sich als äußerst wertvoller Partner erwiesen, indem sie
            kontinuierlich hochwertigen Support und beeindruckende
            Kundenerfahrungen geliefert haben.
          </>
        ),
      },
    ],
  };
  return (
    <div>
      <div className={style.aboutUs}>
        <div className={style.firstSection}>
          <h1 style={{ fontSize: "2.5rem" }}>
            Wir sind ein Dienstleister mit Geschichte
          </h1>
          <p>
            Als erfahrener Dienstleister unterstützen wir Sie dabei, Ihre
            Vertriebsziele zu erreichen und Ihre Markenbotschaft zu verbreiten,
            besonders im B2B-Bereich. Unser Fachgebiet ist die Markenführung im
            Vertrieb, und wir setzen auf neue Qualitätsstandards im
            Telemarketing, um unseren Kunden einen messbaren Mehrwert zu bieten.
          </p>
        </div>
        <div className={style.iconContainer}>
          <div className={style.eachContainer}>
            <span
              className={style.customIconBackground}
              style={{ backgroundColor: "#D4E6FE" }}
            >
              <RocketOutlined className={style.customIcon} />
            </span>
            <span>94+ Erfolgreiche Projekte</span>
          </div>
          <div className={style.eachContainer}>
            <span
              className={style.customIconBackground}
              style={{ backgroundColor: "#FEECD4" }}
            >
              <UserOutlined className={style.customIcon} />
            </span>
            <span>69+ Stammkunden</span>
          </div>
          <div className={style.eachContainer}>
            <span
              className={style.customIconBackground}
              style={{ backgroundColor: "#E5D4FE" }}
            >
              <SafetyOutlined className={style.customIcon} />
            </span>
            <span>10+ Langjährige Erfahrung</span>
          </div>
        </div>
      </div>

      <div className={style.specialCard}>
        <div className={style.lastCard}>
          <h2>Innovation im Kundenerlebnis</h2>
          <p>
            Durch kontinuierliche Innovation garantieren wir begeisternde
            Kundenerlebnisse.
          </p>
        </div>
        <div className={style.lastCard}>
          <h2>Service-Agilität</h2>
          <p>
            Unsere Unterstützung ermöglicht eine agile und dynamische
            Kundenbetreuung.
          </p>
        </div>
        <div className={style.lastCard}>
          <h2>Spezialisten-Expertise</h2>
          <p>
            Unser Expertenteam garantiert eine professionelle Realisierung Ihrer
            Kundenprojekte.
          </p>
        </div>
        <div className={style.lastCard}>
          <h2>Zukunftsfähige Technologien</h2>
          <p>
            Wir bieten die entscheidenden Technologien und das Know-how für die
            Bedürfnisse von morgen.
          </p>
        </div>
      </div>
      {/* <div className={style.specialCard}>
        <div className={style.lastCard}>
          <h2>Innovation im Kundenerlebnis</h2>
          <p>Durch kontinuierliche Innovation garantieren wir begeisternde </p>
        </div>
        <div className={style.lastCard}>
          <h2>Flexibel und dynamisch</h2>
          <p>Auf zukünftige Anforderungen im Kundenservice reagieren.</p>
        </div>
        <div className={style.lastCard}>
          <h2>Ein erfahrenes Team von Spezialisten</h2>
          <p>
            Versteht und erfüllt erfolgreich die individuellen
            Kundenbedürfnisse.
          </p>
        </div>
        <div className={style.lastCard}>
          <h2>Know-how</h2>
          <p>Für aktuelle und zukünftige Technologien und Kapazitäten.</p>
        </div>
      </div> */}
      <div className={style.whyWKSSection}>
        <div className={`${style.card} + card`}>
          <div className={style.cardContent}>
            <h3 className={style.heading6}>Hochwertige Hardware</h3>
            <p className={style.cardsParagraph}>
              Wir verwenden hochwertige Hardware, die regelmäßig aktualisiert
              wird, um Ihren Kunden zuverlässigen Support zu bieten.
            </p>
          </div>
          <div className={style.cardContent}>
            <h3 className={style.heading6}>
              Zeitersparnis mit unseren Beratern
            </h3>
            <p className={style.cardsParagraph}>
              Wenn Sie sich nicht sicher sind, welchen Service Sie benötigen,
              können Sie sich gerne an unsere Berater wenden.
            </p>
          </div>
          <div className={style.cardContent}>
            <h3 className={style.heading6}>Kosten Ihrer Eindämmung</h3>
            <p className={style.cardsParagraph}>
              Die Einstellung der Experten unseres Teams, kann Ihrem Unternehmen
              eine Menge Kosten sparen.
            </p>
          </div>
          <div className={style.cardContent}>
            <h3 className={style.heading6}>
              Beste Qualität, Service und Preis
            </h3>
            <p className={style.cardsParagraph}>
              Unser Ansatz kombiniert diese drei entscheidenden Faktoren, damit
              Sie den maximalen Erfolg erzielen können.
            </p>
          </div>
        </div>
      </div>

      <div className={style.ourTeamSection}>
        <h3>Head - Team</h3>
        <AntRow gutter={[16, 16]} style={{ marginBottom: "6rem" }}>
          {/* {headTeams.map((member) => ( */}
          <AntCol xs={24} sm={12} lg={8} key={1} style={{ marginTop: "7rem" }}>
            <Card className={style.ourTeamCard}>
              <div className={style.iconss}>
                <Avatar
                  size={104}
                  src="/assets/team7.png"
                  style={{
                    borderRadius: "30px 0 30px 0",
                    marginBottom: "1rem",
                    background: "white",
                  }}
                />
                <h5>Florent Rexhepi</h5>
                <p style={{ fontFamily: "Poppins" }}>Leiter SEO</p>
                <div className={style.socialMedia}>
                  <a href="https://facebook.com">
                    <FacebookOutlined className={style.icon} />
                  </a>
                  <a href="https://linkedin.com">
                    <LinkedinOutlined className={style.icon} />
                  </a>
                  <a href="https://twitter.com">
                    <TwitterOutlined className={style.icon} />
                  </a>
                </div>
              </div>
            </Card>
          </AntCol>
          <AntCol xs={24} sm={12} lg={8} key={2}>
            <Card className={style.ourTeamCard}>
              <div className={style.iconss}>
                <Avatar
                  size={104}
                  src="/assets/team2_.png"
                  style={{
                    borderRadius: "30px 0 30px 0",
                    marginBottom: "1rem",
                    background: "white",
                  }}
                />
                <h5>Reinhard Vilic-Plett</h5>
                <p style={{ fontFamily: "Poppins" }}>CEO</p>
                <div className={style.socialMedia}>
                  <a href="https://facebook.com">
                    <FacebookOutlined className={style.icon} />
                  </a>
                  <a href="https://linkedin.com">
                    <LinkedinOutlined className={style.icon} />
                  </a>
                  <a href="https://twitter.com">
                    <TwitterOutlined className={style.icon} />
                  </a>
                </div>
              </div>
            </Card>
          </AntCol>
          <AntCol xs={24} sm={12} lg={8} key={3} style={{ marginTop: "7rem" }}>
            <Card className={style.ourTeamCard}>
              <div className={style.iconss}>
                <Avatar
                  size={104}
                  src="/assets/team1_.png"
                  style={{
                    borderRadius: "30px 0 30px 0",
                    marginBottom: "1rem",
                    background: "white",
                  }}
                />
                <h5>Ajdin Kirov</h5>
                <p style={{ fontFamily: "Poppins" }}>Vertriebsleiter DACH</p>
                <div className={style.socialMedia}>
                  <a href="https://facebook.com">
                    <FacebookOutlined className={style.icon} />
                  </a>
                  <a href="https://linkedin.com">
                    <LinkedinOutlined className={style.icon} />
                  </a>
                  <a href="https://twitter.com">
                    <TwitterOutlined className={style.icon} />
                  </a>
                </div>
              </div>
            </Card>
          </AntCol>
          {/* ))} */}
        </AntRow>
        <h3>Unser Strategieteam</h3>
        <AntRow gutter={[16, 16]}>
          {teamMembers.map((member) => (
            <AntCol xs={24} sm={12} lg={8} key={member.id}>
              <Card className={style.ourTeamCard}>
                <div className={style.iconss}>
                  <Avatar
                    size={104}
                    src={member.image}
                    style={{
                      borderRadius: "30px 0 30px 0",
                      marginBottom: "1rem",
                      background: "white",
                    }}
                  />
                  <h5>{member.name}</h5>
                  <p style={{ fontFamily: "Poppins" }}>{member.position}</p>
                  <div className={style.socialMedia}>
                    <a href={member.social.facebook}>
                      <FacebookOutlined className={style.icon} />
                    </a>
                    <a href={member.social.linkedin}>
                      <LinkedinOutlined className={style.icon} />
                    </a>
                    <a href={member.social.twitter}>
                      <TwitterOutlined className={style.icon} />
                    </a>
                  </div>
                </div>
              </Card>
            </AntCol>
          ))}
        </AntRow>

        {showMore && (
          <>
            <h3 style={{ marginTop: "4rem" }}>Front-Team</h3>
            <AntRow gutter={[16, 16]}>
              {additionalTeamMembers.map((member) => (
                <AntCol xs={24} sm={12} lg={8} key={member.id}>
                  <Card className={style.ourTeamCard}>
                    <div className={style.iconss}>
                      <Avatar
                        size={104}
                        src={member.image}
                        style={{
                          borderRadius: "30px 0 30px 0",
                          marginBottom: "1rem",
                          background: "white",
                        }}
                      />
                      <h5>{member.name}</h5>
                      <p style={{ fontFamily: "Poppins" }}>{member.position}</p>
                      <div className={style.socialMedia}>
                        <a href={member.social.facebook}>
                          <FacebookOutlined className={style.icon} />
                        </a>
                        <a href={member.social.linkedin}>
                          <LinkedinOutlined className={style.icon} />
                        </a>
                        <a href={member.social.twitter}>
                          <TwitterOutlined className={style.icon} />
                        </a>
                      </div>
                    </div>
                  </Card>
                </AntCol>
              ))}
            </AntRow>{" "}
          </>
        )}
        <div style={{ textAlign: "center" }}>
          <button
            onClick={handleShowMore}
            style={{
              marginTop: "2rem",
              padding: "1rem 2rem",
              background: "linear-gradient(-45deg, #ab55a9 0%, #644dd9 100%)",
              borderRadius: "10px",
              border: "none",
              color: "white",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          >
            {showMore ? "Weniger" : "Mehr"}
          </button>
        </div>
      </div>
      <div className={style.helpCenter}>
        <div className={style.helpCenterContent}>
          <h3>Was unsere Partner über uns sagen?</h3>
        </div>
        <Row>
          <Col xs={12} md={4}>
            <div className="d-flex w-100 flex-column gap-2">
              {mainSection &&
                mainSection.length > 0 &&
                mainSection.map((item) => (
                  <div
                    id="qCard"
                    className={`${style.qCard} ${
                      selectedSection === item ? style.selectedCard : ""
                    }`}
                    onClick={() => setSelectedSection(item)}
                  >
                    <h6>{item}</h6>
                    {/* it doesn't work in other ways */}
                    {selectedSection === item ? (
                      <img
                        src="/assets/Whitearrow.svg"
                        alt="arrow"
                        className={style.img}
                      />
                    ) : (
                      <img
                        src="/assets/VectorArrow.svg"
                        alt="arrow"
                        className={style.img}
                      />
                    )}
                  </div>
                ))}
            </div>
          </Col>

          <Col md={8} xs={12} style={{ backgroundColor: "white" }}>
            <Accordion
              defaultActiveKey="0"
              style={{
                display: "flex",
                flexDirection: "column",
                // gap: "44px",
                gap: "24px",
              }}
            >
              {selectedSection &&
                AccordionItems[selectedSection] &&
                AccordionItems[selectedSection].map((item, index) => (
                  <Accordion.Item
                    eventKey={index.toString()}
                    key={index}
                    style={{
                      border: "1px solid #EEF2FF",
                      backgroundColor: "#EEF2FF",

                      padding: "0px 44px",
                    }}
                  >
                    <AccordionButton style={{ background: "none" }}>
                      <span className={style.accordionTitle}>{item.title}</span>
                    </AccordionButton>
                    <Accordion.Body>{item.body}</Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UberUns;
