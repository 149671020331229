import axios from 'axios';
const baseURL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/api'
    : 'https://prodapi.wkscompany.ch/api';

const Axios = axios.create({
    baseURL: baseURL,
});

export default Axios;
