/* eslint-disable */

import React from "react";
import style from "../../styles/MapSection.module.css";

const MapSection = () => {
  return (
    <div className={style.whyWKS}>
      <div className={style.maps}>
        {/* <iframe
          width="50%"
          height="400"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Goldbrunnenstrasse%20429000%20St.%20Gallen,%20Switzerland%20+(WKS)&amp;t=&amp;z=6&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "} */}
        <iframe
          width="50%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Goldbrunnenstrasse%2042%20CH%20-%209000%20St.%20Gallen%20Switzerland+(WKS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "}
        {/* <iframe
          width="50%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Wienerstr.%204a%204020%20Linz%20+(WKS)&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "} */}
        <iframe
          width="50%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Wiener%20Str.%204a%20Wiener%20Str.%204a,%204020%20Linz,%20Austria%20Austria+(WKS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "}
      </div>
      <div className={style.maps}>
        {/* <a href="https://www.versicherungen.at/cyber-versicherung/">
          Hackerangriff Versicherung
        </a>{" "}
        <script
          type="text/javascript"
          src="https://embedmaps.com/google-maps-authorization/script.js?id=0cb6923c4a965eb233568656e6ca86f7a03d011d"
        ></script> */}
        <iframe
          width="50%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Hajrullah%20Abdullahu%20+(WKS)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "}
        <iframe
          width="50%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Te%20Davudi%20XK,%20Galip%20Badivuku,%20Vushtrri%2042000%20Vushtrri+(WKS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "}
      </div>
    </div>
  );
};
export default MapSection;
