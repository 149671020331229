/* eslint-disable */

import React, { useState } from "react";
import style from "./ContactUs.module.css";
import Axios from "../../utils/axios";
import { toast, ToastContainer } from "react-toastify";

const Kontakt = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const clearInputs = () => {
    setName("");
    setEmail("");
    setMessage("");
    setErrors([]);
  };
  const validateForm = () => {
    let tempErrors = {};

    tempErrors.name = name
      ? name.length <= 255
        ? ""
        : "Name cannot exceed 255 characters."
      : "Name is required.";

    tempErrors.email = email
      ? /\S+@\S+\.\S+/.test(email) && email.length <= 255
        ? ""
        : "Email is not valid or exceeds 255 characters."
      : "Email is required.";

    tempErrors.message = message ? "" : "Message is required.";

    setErrors({ ...tempErrors });
    return Object.values(tempErrors).every((x) => x === "");
  };

  // Function to send data when the form is submitted
  const fetchData = async (formData) => {
    try {
      const response = await Axios.post("/contact/store", formData);
      console.log("response", response);
      if (response.status == 201) {
        toast.success("Ihre Nachricht wurde erfolgreich gesendet");
        clearInputs();
      }
    } catch (error) {
      console.log("error", error);
    }
    // try {
    //   const response = await axios.post("/landingpage", formData);
    //   console.log("Data submitted:", response.data);
    //   // Display notification
    //   if (response.data.isSuccess) {
    //     toast.success(response.data.message);
    //     // Clear the form fields
    //     setOrganizationEmail("");
    //     setCompanyName("");
    //     setFirstName("");
    //     setLastName("");
    //     // setContactReason("");
    //     setHowToHelp("");
    //   } else {
    //     toast.error(response.data.message || "Something went wrong!");
    //   }
    // } catch (error) {
    //   console.error("Error submitting data:", error);
    //   toast.error("Error submitting data: " + error.response.data.message);
    // }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // const formData = {
      //   contactUs: {
      //     organizationEmail,
      //     companyName,
      //     firstName,
      //     lastName,
      //     // contactReason,
      //     howToHelp,
      //   },
      // };
      const formData = {
        name,
        email,
        message,
      };
      fetchData(formData);
    } else {
      console.log("Validation failed");
    }
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <div className={style.contactUsSection}>
        <div className={style.contactUsImage}>
          {/* <Card bordered={false} style={{ boxShadow: "none" }}>
            <Row gutter={[16, 16]}>
              <Col span={24} className={style.contactCard}>
                <MailOutlined style={{ fontSize: "28px" }} />
                <Title level={4} style={{ fontWeight: "700" }}>
                  Email Address
                </Title>
                <Text>info@wksteam.cc</Text>
              </Col>
              <Col span={24}>
                <PhoneOutlined style={{ fontSize: "28px" }} />
                <Title level={4} style={{ fontWeight: "700" }}>
                  Phone Number
                </Title>
                <Text>+43 676 55 688 08 - Austria</Text>
                <Text>+383 49 244 880 - Kosovo</Text>
              </Col>
              <Col span={24}>
                <HomeOutlined style={{ fontSize: "28px" }} />
                <Title level={4} style={{ fontWeight: "700" }}>
                  Our Address
                </Title>
                <Text>Wienerstr. 4a 4020 Linz, Austria</Text>
                <Text>Rr. Galip Badiviku 49 42000 Vushtrri, Kosovo</Text>
              </Col>
            </Row>
          </Card> */}
        </div>
        {/* <div className={style.contactUsCard}>
          <h3>Contact us</h3>
          <form className={style.contactUsForm} onSubmit={handleSubmit}>
            <div className={style.contactUsInnerSection}>
              <div className={style.emailInputContainer}>
                <input
                  type="email"
                  placeholder="Organization or Company email"
                  className={style.emailInput}
                  value={organizationEmail}
                  onChange={(e) => setOrganizationEmail(e.target.value)}
                />
                <img
                  src="/assets/smallEmailIcon.svg"
                  alt="email"
                  className={style.emailIcon}
                />
                {errors.organizationEmail && (
                  <div className={style.errorMsg}>
                    {errors.organizationEmail}
                  </div>
                )}
              </div>
              <div className={style.emailInputContainer}>
                <input
                  type="text"
                  placeholder="Company name"
                  className={style.emailInput}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <img
                  src="/assets/smallHomeIcon.svg"
                  alt="company"
                  className={style.emailIcon}
                />
                {errors.companyName && (
                  <div className={style.errorMsg}>{errors.companyName}</div>
                )}
              </div>
            </div>
            <div className={style.contactUsInnerSection}>
              <div className={style.emailInputContainer}>
                <input
                  type="text"
                  placeholder="First name"
                  className={style.emailInput}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <img
                  src="/assets/smallPersonIcon.svg"
                  alt="person"
                  className={style.emailIcon}
                />
                {errors.firstName && (
                  <div className={style.errorMsg}>{errors.firstName}</div>
                )}
              </div>
              <div className={style.emailInputContainer}>
                <input
                  type="text"
                  placeholder="Last name"
                  className={style.emailInput}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <img
                  src="/assets/smallPersonIcon.svg"
                  alt="person"
                  className={style.emailIcon}
                />
                {errors.lastName && (
                  <div className={style.errorMsg}>{errors.lastName}</div>
                )}
              </div>
            </div>

            <textarea
              placeholder="How can we help you?"
              className={style.text}
              value={howToHelp}
              onChange={(e) => setHowToHelp(e.target.value)}
            />
            {errors.howToHelp && (
              <div className={style.errorMsgForSubmit}>{errors.howToHelp}</div>
            )}
            <div>
              <button type="submit" className={style.submitButton}>
                Submit information{" "}
                <img src="/assets/Whitearrow.svg" alt="arrow white" />
              </button>
            </div>
          </form>
        </div> */}
      </div>
      <section className={style.sectionBg} data-scroll-index="7">
        <div className={`${style.overlay} "pt-100 pb-100 "`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className={style.contactInfo}>
                  <h2 className={style.contactTitle}>Haben Sie Fragen?</h2>
                  <p>
                    Möchten Sie Ihre Probleme lösen? <br />
                    Kontaktieren Sie uns, denn wir sind die richtige Lösung
                  </p>
                  <ul className={style.contactInfo}>
                    <li>
                      <div className={style.infoLeft}>
                        <i className="fas fa-mobile-alt"></i>
                      </div>
                      <div className={style.infoRight}>
                        {/* <h4>+11223344550</h4> */}
                        <h4>+41 71 351 37 67 - Schweiz</h4>
                        <h4>+43 676 55 688 08 - Austria</h4>
                        <h4>+383 49 244 880 - Kosovo</h4>
                      </div>
                    </li>
                    <li>
                      <div className={style.infoLeft}>
                        <i className="fas fa-at"></i>
                      </div>
                      <div className={style.infoRight}>
                        {/* <h4>info@wksteam.cc</h4> */}
                        <h4>info@wkscompany.ch</h4>
                      </div>
                    </li>
                    <li>
                      <div className={style.infoLeft}>
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                      <div className={style.infoRight}>
                        <h4>
                          Goldbrunnenstrasse 42
                          <br /> CH - 9000 St. Gallen{" "}
                        </h4>
                        <h4>
                          Wienerstr. 4a <br /> A - 4020 Linz
                        </h4>
                        <h4>
                          Rr. Hajrullah Abdullahu
                          <br /> RKS - 10000 Pristina
                        </h4>
                        <h4>
                          Rr. Galip Badiviku 49 <br />
                          RKS - 42000 Vushtrri
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                style={{ background: "white" }}
              >
                <div className={style.contactForm}>
                  <form id="contact-form">
                    <input type="hidden" name="form-name" value="contactForm" />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="first-name"
                            placeholder="Bitte geben Sie Ihren Namen ein *"
                            required="required"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Bitte geben Sie Ihre E-Mail-Adresse ein *"
                            required="required"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            rows="4"
                            name="message"
                            className="form-control"
                            id="description"
                            placeholder="Bitte geben Sie Ihre Nachricht ein *"
                            required="required"
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          onClick={handleSubmit}
                          className={`${style.btnBig} ${style.btn} ${style.btnBg}`}
                        >
                          Absenden <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Kontakt;
