// /* eslint-disable */
// import React from "react";
// import style from "../../styles/ClientSection.module.css";

// const ClientSection = () => {
//   return (
//     <div className={style.clientsSection}>
//       <h3 className={style.h3}>Unsere Partner</h3>
//       <div className={style.clientsSectionContent}>
//         <img src="/assets/client1.png" alt="hopp" className={style.img} />
//         <img src="/assets/client2.png" alt="schuzdilt" className={style.img} />
//         <img src="/assets/client3.png" alt="safemask" className={style.img} />
//         <img src="/assets/client4.png" alt="baulonger" className={style.img} />
//         {/* <img src="/assets/client5.png" alt="baulonger" className={style.img} /> */}
//         {/* <img src="/assets/client6.png" alt="baulonger" className={style.img} /> */}
//         {/* <img
//           src="/assets/image11Client.svg"
//           alt="image11Client"
//           className={style.img}
//         />
//         <img
//           src="/assets/global-communitiesClient.svg"
//           alt="global-communitiesClient"
//           className={style.img}
//         /> */}
//       </div>
//     </div>
//   );
// };
// export default ClientSection;
// ClientSection.jsx
import React, { useRef, useEffect, useState } from "react";
import { Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "../../styles/ClientSection.module.css";
import Slider from "react-slick";
import client1 from "../../assets/client1.png";
import client2 from "../../assets/client2.png";
import client3 from "../../assets/client3.png";
import client4 from "../../assets/client4.png";
import client5 from "../../assets/client5.png";
import client6 from "../../assets/client6.png";
import ibeko from "../../assets/ibeko.png";
import econelo from "../../assets/econelo.png";
import amr from "../../assets/amr.png";
import imleipzig from "../../assets/imleipzig.png";
const ClientSection = () => {
  const [logos, setLogos] = useState([
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    ibeko,
    econelo,
    amr,
    imleipzig,
  ]);

  const [settings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
  });
  return (
    <div className={style.clientsSection}>
      <h3 className={style.h3}>Unsere Partner</h3>
      <div>
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="container">
              <img src={logo} alt="logo" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientSection;
