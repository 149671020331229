import React from "react";
// import { useRouter } from "next/router";
import NNavbar from "./NNavbar";
import Header from "./Header";
import Footer from "./Footer";
import AboutWKSSection from "./AboutWKSSection";
import TransformingDataSection from "./TransformingDataSection";
import ClientSection from "./ClientsSection";
import MapSection from "./MapSection";
import DienstleistungenSection from "./DienstleistungenSection";
import { useLocation } from "react-router-dom";

const Layout = () => {
  // const router = useRouter();
  const router = useLocation();

  // Directly check for client-side environment
  // This can be done by checking if window is defined
  const isClient = typeof window !== "undefined";

  // Render only if on the client-side and the current path is the homepage
  const renderHomePageSections = isClient && router.pathname === "/";

  return (
    <>
      {renderHomePageSections && <Header />}
      {renderHomePageSections && (
        <>
          <AboutWKSSection />
          <TransformingDataSection />
          <ClientSection />
          <DienstleistungenSection />
          <MapSection />
        </>
      )}
    </>
  );
};

export default Layout;
