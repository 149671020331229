/* eslint-disable */

import React, { useState } from "react";
import style from "./Dienstleistungen.module.css";
import arrow from "../../assets/orangeArrow.svg";
import kundenservice from "../../assets/kundenservice.svg";
// import inbound from "../../assets/inbounded.jpg";
import tick from "../../assets/tick.svg";
// import outbound from "../../assets/outbound.svg";

const cardsData = [
  {
    title: "Hochwertige Hardware",
    paragraph:
      "Wir verwenden hochwertige Hardware, die regelmäßig aktualisiert wird, um Ihren Kunden zuverlässigen Support zu bieten.",
  },
  {
    title: "Engagierte 24/7 Unterstützung",
    paragraph:
      "Unser Team arbeitet rund um die Uhr, um Ihre Kunden und Partner umfassend zu unterstützen.",
  },
  {
    title: "Zeitersparnis mit unseren Beratern",
    paragraph:
      "Wenn Sie sich nicht sicher sind, welchen Service Sie benötigen, können Sie sich gerne an unsere Berater wenden.",
  },
  {
    title: "Kosten Ihrer Eindämmung",
    paragraph:
      "Die Einstellung der Experten unseres WKS-Teams kann Ihrem Unternehmen eine Menge Kosten sparen.",
  },
  {
    title: "Beste Qualität, Service und Preis",
    paragraph:
      "Unser Ansatz kombiniert diese drei entscheidenden Faktoren, damit Sie den maximalen Erfolg erzielen können.",
  },
  {
    title: "Risiko und Kostendämmung",
    paragraph:
      "Unser Ziel ist es, Ihre Ausgaben der Dienstleistungen zu minimieren und gleichzeitig Hilfe zu geben.",
  },
];

const Dienstleistungen = () => {
  return (
    <div>
      {/* <div className={style.beneficiaryFeedback}>
        <div className={style.beneficiaryFeedbackSection}>
          <h3 className={style.beneficiaryFeedbackH3}>Dienstleistungen</h3>
      
        </div>
      </div> */}
      <div className={style.servicesSection}>
        <div className={style.services}>
          <h3>Das WKS-Team</h3>
          <p style={{ textAlign: "center", fontFamily: "Lato" }}>
            <strong> Wir sind immer für Sie da. </strong>
            <br />
            Gute Erreichbarkeit ist die Grundvoraussetzung für jedes
            Serviceerlebnis. Um Ihren Kunden ein optimales Kundencenter zu
            bieten, sind wir während 365 Tagen und 24 Stunden erreichbar.
          </p>
        </div>

        <div className={style.servicesSectionContent}>
          {/* <div className={`${style.cards} + card`}>
            <img
              src="/assets/callCenter.png"
              style={{ width: "54px", height: "54px" }}
              alt="charts"
            />

            <h6 className={style.heading6} style={{ textAlign: "center" }}>
              Hochwertige Hardware
            </h6>
            <p className={style.cardsParagraph}>
              Wir verwenden hochwertige Hardware, die regelmäßig aktualisiert
              wird, um Ihren Kunden zuverlässigen Support zu bieten.
            </p>
          </div>
          <div className={`${style.cards} + card`}>
            <img
              src="/assets/callCenter.png"
              style={{ width: "54px", height: "54px" }}
              alt="charts"
            />
            <h6 className={style.heading6} style={{ textAlign: "center" }}>
              Engagierte 24\7 Unterstützung
            </h6>
            <p className={style.cardsParagraph}>
              Unser Team arbeitet rund um die Uhr, um Ihre Kunden und Partner
              umfassend zu unterstützen.
            </p>
          </div>
          <div className={`${style.cards} + card`}>
            <img
              src="/assets/callCenter.png"
              style={{ width: "54px", height: "54px" }}
              alt="charts"
            />
            <h6 className={style.heading6} style={{ textAlign: "center" }}>
              Zeitersparnis mit unseren Beratern
            </h6>
            <p className={style.cardsParagraph}>
              Wenn Sie sich nicht sicher sind, welchen Service Sie benötigen,
              können Sie sich gerne an unsere Berater wenden.
            </p>
          </div> */}
          {cardsData.slice(0, 3)?.map((item, key) => (
            <div className={style.parent} key={key}>
              <div className={style.card}>
                <div className={style.logo}>
                  <span className={`${style.circle} ${style.circle1}`}></span>
                  <span className={`${style.circle} ${style.circle2}`}></span>
                  <span className={`${style.circle} ${style.circle3}`}></span>
                  <span className={`${style.circle} ${style.circle4}`}></span>
                  <span className={`${style.circle} ${style.circle5}`}>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 29.667 31.69"
                      className={style.svg}
                    >
                      <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M12.827,1.628A1.561,1.561,0,0,1,14.31,0h2.964a1.561,1.561,0,0,1,1.483,1.628v11.9a9.252,9.252,0,0,1-2.432,6.852q-2.432,2.409-6.963,2.409T2.4,20.452Q0,18.094,0,13.669V1.628A1.561,1.561,0,0,1,1.483,0h2.98A1.561,1.561,0,0,1,5.947,1.628V13.191a5.635,5.635,0,0,0,.85,3.451,3.153,3.153,0,0,0,2.632,1.094,3.032,3.032,0,0,0,2.582-1.076,5.836,5.836,0,0,0,.816-3.486Z"
                        transform="translate(0 0)"
                      ></path>
                      <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M75.207,20.857a1.561,1.561,0,0,1-1.483,1.628h-2.98a1.561,1.561,0,0,1-1.483-1.628V1.628A1.561,1.561,0,0,1,70.743,0h2.98a1.561,1.561,0,0,1,1.483,1.628Z"
                        transform="translate(-45.91 0)"
                      ></path>
                      <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M0,80.018A1.561,1.561,0,0,1,1.483,78.39h26.7a1.561,1.561,0,0,1,1.483,1.628v2.006a1.561,1.561,0,0,1-1.483,1.628H1.483A1.561,1.561,0,0,1,0,82.025Z"
                        transform="translate(0 -51.963)"
                      ></path>
                    </svg> */}
                    <svg
                      height="30"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      className={style.svg}
                    >
                      <text x="5" y="15" fill="white">
                        WKS
                      </text>
                    </svg>
                  </span>
                </div>
                <div className={style.glass}></div>
                <div className={style.contentCardddd}>
                  <span className={style.title}>{item.title}</span>
                  <span className={style.text}>{item.paragraph}</span>
                </div>
                <div className={style.bottom}>
                  <div className={style.socialButtonsContainer}>
                    <button
                      className={`${style.socialButton} ${style.socialButton1}`}
                    >
                      {/* <svg
                     viewBox="0 0 30 30"
                     xmlns="http://www.w3.org/2000/svg"
                     className={style.svg}
                   >
                     <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                   </svg> */}
                      W
                    </button>
                    <button
                      className={`${style.socialButton} ${style.socialButton2}`}
                    >
                      {/* <svg
                     viewBox="0 0 512 512"
                     xmlns="http://www.w3.org/2000/svg"
                     className={style.svg}
                   >
                     <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                   </svg> */}
                      K
                    </button>
                    <button
                      className={`${style.socialButton} ${style.socialButton3}`}
                    >
                      S
                      {/* <svg
                     viewBox="0 0 640 512"
                     xmlns="http://www.w3.org/2000/svg"
                     className={style.svg}
                   >
                     <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
                   </svg> */}
                    </button>
                  </div>
                  {/* <div className={style.viewMore}>
                    <button className={style.viewMoreButton}>View more</button>
                    <svg
                      className={style.svg}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m6 9 6 6 6-6"></path>
                    </svg>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={style.servicesSectionContent}
          style={{ marginTop: "2rem" }}
        >
          {cardsData.slice(3, 6)?.map((item, key) => (
            <div className={style.parent} key={key}>
              <div className={style.card}>
                <div className={style.logo}>
                  <span className={`${style.circle} ${style.circle1}`}></span>
                  <span className={`${style.circle} ${style.circle2}`}></span>
                  <span className={`${style.circle} ${style.circle3}`}></span>
                  <span className={`${style.circle} ${style.circle4}`}></span>
                  <span className={`${style.circle} ${style.circle5}`}>
                    <svg
                      height="30"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      className={style.svg}
                    >
                      <text x="5" y="15" fill="white">
                        WKS
                      </text>
                    </svg>
                  </span>
                </div>
                <div className={style.glass}></div>
                <div className={style.contentCardddd}>
                  <span className={style.title}>{item.title}</span>
                  <span className={style.text}>{item.paragraph}</span>
                </div>
                <div className={style.bottom}>
                  <div className={style.socialButtonsContainer}>
                    <button
                      className={`${style.socialButton} ${style.socialButton1}`}
                    >
                      {/* <svg
                     viewBox="0 0 30 30"
                     xmlns="http://www.w3.org/2000/svg"
                     className={style.svg}
                   >
                     <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                   </svg> */}
                      W
                    </button>
                    <button
                      className={`${style.socialButton} ${style.socialButton2}`}
                    >
                      {/* <svg
                     viewBox="0 0 512 512"
                     xmlns="http://www.w3.org/2000/svg"
                     className={style.svg}
                   >
                     <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                   </svg> */}
                      K
                    </button>
                    <button
                      className={`${style.socialButton} ${style.socialButton3}`}
                    >
                      S
                      {/* <svg
                     viewBox="0 0 640 512"
                     xmlns="http://www.w3.org/2000/svg"
                     className={style.svg}
                   >
                     <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
                   </svg> */}
                    </button>
                  </div>
                  {/* <div className={style.viewMore}>
                    <button className={style.viewMoreButton}>View more</button>
                    <svg
                      className={style.svg}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m6 9 6 6 6-6"></path>
                    </svg>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={style.secondSection}>
        <div className={style.sectionInnerSection}>
          <div className={style.createSurveySection}>
            <h3>Kundenservice</h3>
            <div className={style.surveyContent}>
              <div className={style.innerContent}>
                <img src={arrow} alt="arrow" />
                <p>
                  Unser Kundenservice geht über das Grundlegende hinaus. Wir
                  erkennen die tiefen Bedürfnisse unserer Kunden und garantieren
                  echte Zufriedenheit sowie langfristige Treue.
                </p>
              </div>
              <div className={style.innerContent}>
                <img src={arrow} alt="arrow" />
                <p>
                  Umfassender Service: Unsere umfassenden Dienstleistungen
                  repräsentieren Ihr Unternehmen authentisch und nutzen
                  intelligente Analysetools für personalisierte Angebote und
                  Umsatzsteigerungen.
                </p>
              </div>
              <div className={style.innerContent}>
                <img src={arrow} alt="arrow" />
                <p>
                  {/* Erreichbarkeit: Wir sind überall erreichbar – von Hotlines bis
                  hin zu digitalen Kanälen –, um sicherzustellen, dass unsere */}
                  Erreichbarkeit: Wir sind überall erreichbar, von Hotlines bis
                  hin zu digitalen Kanälen, um unseren Kunden durchgehend
                  Support zu bieten.
                </p>
              </div>
            </div>
          </div>
          <div className={style.servicesDiv}>
            <img
              src="/assets/kundenservicePhoto2.jpg"
              alt="survey"
              className={style.beneficiaryFeedbackImg}
            />
          </div>
        </div>
      </div>
      <div className={style.firstSection}>
        <div className={style.sectionInnerSection}>
          <div className={style.servicesDiv}>
            <img
              src="/assets/inbounded.jpg"
              alt="survey"
              className={style.beneficiaryFeedbackImg}
            />
          </div>
          <div className={style.createSurveySection}>
            <h3 className={style.inboundH3}>Inbound</h3>
            <div className={style.surveyContent}>
              <div className={style.innerContent}>
                <img src={tick} alt="arrow" />
                <p className={style.inboundP}>
                  WKS fungiert als primäre Kontaktperson für Ihre Kunden und
                  bietet einen umfassenden Kundenservice über alle
                  Kommunikationskanäle hinweg. Unsere kompetenten Mitarbeiter
                  stehen bereit, um Anrufe, E-Mails, Chats und Anfragen in
                  sozialen Medien entweder direkt zu beantworten oder sie bei
                  Bedarf entsprechend weiterzuleiten.
                </p>
              </div>
              <div className={style.innerContent}>
                <img src={tick} alt="arrow" />
                <p className={style.inboundP}>
                  Durch eine rasche Reaktionszeit und einen gewissenhaften
                  Betrieb tragen wir dazu bei, Ihr Image zu stärken.
                </p>
              </div>
              <div className={style.innerContent}>
                <img src={tick} alt="arrow" />
                <p className={style.inboundP}>
                  WKS garantiert reibungslose Abläufe, bietet zusätzliche
                  Kapazitäten und Professionalität, um Stoßzeiten zu bewältigen
                  und eine Kundenbetreuung auch außerhalb der üblichen
                  Geschäftszeiten zu gewährleisten.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.secondSection}>
        <div className={style.sectionInnerSection}>
          <div className={style.createSurveySection}>
            <h3>Outbound</h3>
            <div className={style.surveyContent}>
              <div className={style.innerContent}>
                <img src={arrow} alt="arrow" />
                <p>
                  WKS spezialisiert sich auf effektives Outbound-Direktmarketing
                  durch professionell geführte Verkaufsgespräche, um
                  Neukundengewinnung und Cross- sowie Upselling zu optimieren.
                </p>
              </div>
              <div className={style.innerContent}>
                <img src={arrow} alt="arrow" />
                <p>
                  Unsere erfahrenen Agenten repräsentieren Ihr Unternehmen mit
                  Fachkompetenz und respektvollem Umgang.
                </p>
              </div>
              <div className={style.innerContent}>
                <img src={arrow} alt="arrow" />
                <p>
                  Wir legen großen Wert auf die Qualität und kontinuierliche
                  Überwachung der Gesprächsführung, um Ihnen präzise
                  Marktanalysen und wertvolle Einblicke zu bieten.
                </p>
              </div>
            </div>
          </div>
          <div className={style.servicesDiv}>
            <img
              src="/assets/outboundService.jpg"
              alt="survey"
              className={style.beneficiaryFeedbackImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dienstleistungen;
