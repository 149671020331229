/* eslint-disable */

import React from "react";
import style from "../../styles/DienstleistungenSection.module.css";
import line from "../../assets/line.svg";
const DienstleistungenSection = () => {
  return (
    <div className={style.dienstleistungenSection}>
      <h1 className={style.h1}>Dienstleistungen</h1>

      <div className={style.maximizeSectionFirstContent}>
        <div>
          <div className={style.dataCollection}>
            <div>
              <h5 className={style.h5}>1. Kundenservice</h5>
              <p className={style.p}>
                Unser Service ermöglicht Entlastung anstelle von Belastung.
              </p>
            </div>
          </div>
          <img src={line} alt="line" className={style.line} />
        </div>
        <div>
          <div className={style.dataCollection}>
            {/* <img src="/assets/dCollection.svg" alt="data" /> */}
            <div>
              <h5 className={style.h5}>2. Inbound</h5>
              <p className={style.p}>
                Kompetenter Kundenservice für ein perfektes Image
              </p>
            </div>
          </div>
          <img src={line} alt="line" className={style.line} />
        </div>
        <div>
          <div className={style.dataCollection}>
            {/* <img src="/assets/createWorkspace.svg" alt="create" /> */}
            <div>
              <h5 className={style.h5}>3. Outbound</h5>
              <p className={style.p}>
                Erfolgreiche Unterstützung im Verkauf durch Cross- und
                Up-Selling.
              </p>
            </div>
          </div>
          <img src={line} alt="line" className={style.line} />
        </div>
      </div>
      <div className={style.buttonRow}>
        <a className={style.button} href="/dienstleistungen">
          Dienstleistungen
          {/* <img src="/assets/whitearrow.svg" alt="shape" /> */}
        </a>
      </div>
    </div>
  );
};
export default DienstleistungenSection;
