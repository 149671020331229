/* eslint-disable */
import React from "react";
import style from "../../styles/Footer.module.css";
import wksLogo from "../../assets/wkslogo.png";
import email from "../../assets/mail.svg";
import phone from "../../assets/phone.svg";
const Footer = () => {
  // Function to handle WhatsApp redirect
  const redirectToWhatsApp = (phoneNumber) => {
    // Removing any special characters from phone number
    const cleanNumber = phoneNumber.replace(/[^\d]/g, "");
    return `https://wa.me/${cleanNumber}`;
  };
  return (
    <div className={style.footerSection}>
      <div className={style.firstRow}>
        <div>
          <img
            // src="/assets/llogoremovebg.png"
            src="/assets/finalLogo.png"
            alt="WKS logo"
            className={style.wksLogo}
          />
          <p className={style.p}>
            <strong>
              Seit 2012 ist WKS im Geschäft und bietet eine Menge
              Direktmarketing-Services an. <br />
              Wir konzentrieren uns darauf, die Bedürfnisse unserer Kunden
              effizient und professionell zu erfüllen.
            </strong>
          </p>
        </div>
      </div>
      <div className={style.secondPart}>
        <div className={style.contactSuportBrandsSection}>
          <h5 className={style.h5}>Links</h5>
          <div className={style.supportBrandContent}>
            <h5 className={style.h7}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/uber-uns"
              >
                Über Uns
              </a>
            </h5>

            <h5 className={style.h7}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/dienstleistungen"
              >
                Dienstleistungen
              </a>
            </h5>
            <h5 className={style.h7}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/kontakt"
              >
                Kontakt
              </a>
            </h5>
          </div>
        </div>
        <div className={style.contactSuportBrandsSection}>
          <h5 className={style.h5}>Dienstleistungen</h5>
          <div className={style.supportBrandContent}>
            <h5 className={style.h7}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/dienstleistungen"
              >
                Kundenservice
              </a>
            </h5>
            <h5 className={style.h7}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/dienstleistungen"
              >
                Inbound
              </a>
            </h5>
            <h5 className={style.h7}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/dienstleistungen"
              >
                Outbound
              </a>
            </h5>
          </div>
        </div>
        <div className={style.contactSuportBrandsSection}>
          <h5 className={style.h5}>Kontakt</h5>
          <div className={style.contactContent}>
            <div className={style.contentt}>
              <img src={phone} alt="phone" />
              <a
                className={style.h7}
                href={redirectToWhatsApp("+ 41 71 351 37 67")}
                target="_blank" // Open in new tab
                rel="noopener noreferrer" // Security for opening links in new tab
                style={{ textDecoration: "none" }}
              >
                + 41 71 351 37 67
              </a>
            </div>
            <div className={style.contentt}>
              <img src={email} alt="mail" />
              <a
                className={style.h7}
                style={{ textDecoration: "none" }}
                href={"mailto: info@wksteam.cc"}
              >
                {/* info@wksteam.cc */}
                info@wkscompany.ch
              </a>
            </div>
            <div className={style.contentt}>
              {/* <i
                className="fas fa-map-marker-alt"
                style={{ color: "white" }}
              ></i> */}
              <h5 className={style.h7Contact}>
                Goldbrunnenstrasse 42 <br />
                CH - 9000 St. Gallen
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
