/* eslint-disable */

import React from "react";
import { Container } from "react-bootstrap";
import style from "../../styles/Header.module.css";
import bannerImg from "../../assets/home-banner-img.png";
import wave from "../../assets/waveSVG.svg";
const Header = () => {
  return (
    <header className={style.headerStyle}>
      <Container className={style.container}>
        <div className={style.partOne} data-wow-duration="2s">
          <div className={style.content}>
            <h1 className="text-white">
              WKS Company <br />
              <span className={style.span}>
                Unsere Stimme
                <br />
                Für Ihren Erfolg
              </span>
            </h1>
          </div>
          <p className={style.paragraphhh}>
            Seriös & Zuverlässig{/* <br /> */}
            <br />
          </p>
          {/* <a className={style.buttonStyle} href="/uber-uns">
            Über Uns <img src="/assets/shape.svg" alt="shape" />
          </a> */}
          <a className={style.buttonStyle} href="/uber-uns">
            Über Uns
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5l7 7-7 7" />
            </svg>
          </a>
        </div>
        <img src={bannerImg} alt="Banner" className={style.imageStyle} />
        <img src={wave} alt="Wave" className={style.waveStyle} />
      </Container>
    </header>
  );
};

export default Header;
