/* eslint-disable */

import React from "react";
import style from "../../styles/AboutWKSSection.module.css";

const AboutWKSSection = () => {
  // const aboutWKSSectionStyle = {
  //   marginTop: "40px",
  //   padding: "0 6rem",
  // };
  // const heading3Style = {
  //   fontFamily: "Poppins",
  //   fontSize: "24px",
  //   fontWeight: "700",
  //   lineHeight: "34.56px",
  // };
  // const buttonStyle = {
  //   backgroundColor: "#F58F45",
  //   border: "#F58F45",
  //   borderRadius: "4px",
  //   padding: "12px 24px 12px 24px",
  //   gap: "10px",
  //   color: "white",
  //   textAlign: "center",
  //   textDecoration: "none",
  // };
  return (
    <>
      <div className={style.aboutWKSSectionStyle}>
        <div className={style.content}>
          <h1 className={style.h1}>Was machen wir?</h1>
          <p className={style.p}>
            {/* Seit 2012 ist WKS erfolgreich am Markt und bietet das gesamte
            Spektrum des Direktmarketings & Kundenservice an. */}
            Der Erfolg, den wir seit 2012 vorweisen können, macht die Firma WKS
            zu einer Ihrer Lösungen.
          </p>
        </div>
        <div className={style.specialCard}>
          <div className={style.serviceCardContainer}>
            <div className={style.serviceCard}>
              <div className={style.frontContent}>
                <p>Unsere Vision</p>
              </div>
              <div className={style.serviceContent}>
                <p className={style.serviceHeading}>Unsere Vision</p>
                <p>
                  Unsere Kunden verstehen wir als Partner.
                  <br /> Unser Ziel ist es, ihnen qualitativ hochwertige
                  Kundenkontakte über die verschiedensten Kommunikationskanäle
                  zu ermöglichen.
                </p>
              </div>
            </div>
          </div>
          <div className={style.serviceCardContainer}>
            <div className={style.serviceCard}>
              <div className={style.frontContent}>
                <p>Unser Auftrag</p>
              </div>
              <div className={style.serviceContent}>
                <p className={style.serviceHeading}>Unser Auftrag</p>
                <p>
                  Erfolg beruht auf Vertrauen, welches die Grundlage jeder guten
                  Beziehung bildet. Eine starke und stabile Beziehung entsteht
                  aus einem hohen Maß an Vertrauen. <br />
                  Als erfolgreiches Unternehmen legen wir daher großen Wert auf
                  die Pflege unserer Beziehungen.
                </p>
              </div>
            </div>
          </div>
          <div className={style.serviceCardContainer}>
            <div className={style.serviceCard}>
              <div className={style.frontContent}>
                <p>Werte & Kultur</p>
              </div>
              <div className={style.serviceContent}>
                <p className={style.serviceHeading}>Werte & Kultur</p>
                <p>
                  Alles, was wir tun, basiert auf gemeinsamen Werten, die
                  definieren, wie wir mit unseren Kunden, unseren Mitarbeitenden
                  und der Gesellschaft umgehen – weltweit. <br />
                  Als Dienstleister setzen wir all unsere Erfahrung und
                  Leidenschaft für Ihre Interessen ein.
                </p>
              </div>
            </div>
          </div>
          <div className={style.serviceCardContainer}>
            <div className={style.serviceCard}>
              <div className={style.frontContent}>
                <p>Zukunftsgestaltung</p>
              </div>
              <div className={style.serviceContent}>
                <p className={style.serviceHeading}>Zukunftsgestaltung</p>
                <p>
                  Innovation und Fortschritt bilden das Fundament unserer
                  zukünftigen Entwicklung. <br />
                  Unser Ziel ist es, Trends zu setzen und aktiv am
                  Marktgeschehen teilzuhaben
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={style.buttonsDiv}
          style={{ textAlign: "center", marginTop: "60px" }}
        >
          <a className={style.exploreAllbutton} href="/uber-uns">
            Erfahren Sie mehr über uns
          </a>
        </div>
      </div>
      {/* <div className={style.aboutWKSSectionStyle}>
        <div className={style.content}>
          <h1 className={style.h1}>Was machen wir?</h1>
          <p className={style.p}>
            Seit 2015 ist WKS erfolgreich am Markt und bietet das gesamte
            Spektrum des Direktmarketings & Kundenservice an.
          </p>
        </div>
        <div className={style.firstSection}>
          <div className={`${style.card} + "card"`}>
            <div className="inner-card">
              <h3 className={style.heading3Style}>Unsere Vision</h3>
              <div className={style.contentInnerCard}>
                <img src="/assets/tick.svg" alt="tick" />

                <p style={{ marginLeft: "1rem", fontFamily: "Lato" }}>
                  Unsere Kunden verstehen wir als Partner.
                </p>
              </div>
              <div className={style.contentInnerCard}>
                <img src="/assets/tick.svg" alt="tick" />

                <p style={{ marginLeft: "1rem", fontFamily: "Lato" }}>
                  Unser Ziel ist es, ihnen qualitativ hochwertige Kundenkontakte
                  über die verschiedensten Kommunikationskanäle zu ermöglichen.
                </p>
              </div>
             
              <img
                src="/assets/blog-post-img-1.png"
                alt="data collection"
                className={style.dataCollectionImg}
              />
            </div>
          </div>
          <div className={`${style.secondCard} + "card"`}>
            <div className={style.secondInnerCard}>
              <div>
                <h3 className={style.heading3Style}>Unser Auftrag</h3>
                <div className={style.secondInnerCardContent}>
                  <img src="/assets/tick.svg" alt="tick" />

                  <p
                    style={{
                      marginLeft: "1rem",
                      fontSize: "16px",
                      fontFamily: "Lato",
                    }}
                  >
                    <strong>
                      Erfolg ist Vertrauenssache. Vertrauen ist die Basis jeder
                      guten Beziehung.
                    </strong>
                  </p>
                </div>
                <div className={style.secondInnerCardContent}>
                  <img src="/assets/tick.svg" alt="tick" />

                  <p
                    style={{
                      marginLeft: "1rem",
                      fontSize: "16px",
                      fontFamily: "Lato",
                    }}
                  >
                    <strong>
                      Je größer das Vertrauen, umso stärker und stabiler die
                      Beziehung.
                    </strong>
                  </p>
                </div>
                <div className={style.secondInnerCardContent}>
                  <img src="/assets/tick.svg" alt="tick" />

                  <p
                    style={{
                      marginLeft: "1rem",
                      fontSize: "16px",
                      fontFamily: "Lato",
                    }}
                  >
                    <strong>
                      Als erfolgreiches Unternehmen pflegen wir deshalb unsere
                      Beziehungen.
                    </strong>
                  </p>
                </div>
              </div>
              <img
                src="/assets/image4.png"
                alt="task management"
                className={style.taskManagementImg}
              />
            </div>
          </div>
        </div>

        <div className={style.secondSection}>
          <div className={`${style.secondSectionCard} + "card"`}>
            <div className="">
              <div className={style.secondSectionInnerCard}>
                <h3 className={style.heading3Style}>Werte & Kultur </h3>
                <div className={style.secondSectionInnerCardContent}>
                  <img src="/assets/tick.svg" alt="tick" />

                  <p
                    style={{
                      marginLeft: "1rem",
                      fontSize: "16px",
                      fontFamily: "Lato",
                    }}
                  >
                    <strong>
                      Alles, was wir tun, basiert auf gemeinsamen Werten, die
                      definieren, wie wir mit unseren Kunden, unseren
                      Mitarbeitenden und der Gesellschaft umgehen – weltweit.
                    </strong>
                  </p>
                </div>
                <div className={style.secondSectionInnerCardContent}>
                  <img src="/assets/tick.svg" alt="tick" />

                  <p
                    style={{
                      marginLeft: "1rem",
                      fontSize: "16px",
                      fontFamily: "Lato",
                    }}
                  >
                    <strong>
                      Wir bleiben für Sie am Ball und unterstützen Sie bei der
                      Erreichung Ihrer Vertriebsziele und bei der Verbreitung
                      Ihrer Markenbotschaft.
                    </strong>
                  </p>
                </div>
                <div className={style.secondSectionInnerCardContent}>
                  <img src="/assets/tick.svg" alt="tick" />

                  <p
                    style={{
                      marginLeft: "1rem",
                      fontSize: "16px",
                      fontFamily: "Lato",
                    }}
                  >
                    <strong>
                      Als Dienstleister setzen wir all unsere Erfahrung und
                      Leidenschaft für Ihre Interessen ein.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <img
              src="/assets/image3.png"
              alt="beneficieries mockup"
              className={style.beneficieriesImg}
            />
          </div>
          <div className={`${style.secondSectionSecondCard} + "card"`}>
            <div className={style.secondSectionSecondInnerCard}>
              <h3 className={style.heading3Style}>Zukunftsgestaltung</h3>
              <div className={style.secondSectionSecondInnerCardContent}>
                <img src="/assets/tick.svg" alt="tick" />

                <p style={{ marginLeft: "1rem", fontFamily: "Lato" }}>
                  <strong>
                    Innovation und Fortschritt sind die Eckpfeiler unserer
                    Zukunft.
                  </strong>
                </p>
              </div>
              <div
                className={style.secondSectionSecondInnerCardContent}
                style={{ alignItems: "baseline" }}
              >
                <img src="/assets/tick.svg" alt="tick" />

                <p style={{ marginLeft: "1rem", fontFamily: "Lato" }}>
                  <strong>
                    Es ist unser Anspruch, Trends zu setzen und den Markt aktiv
                    mitzugestalten.
                  </strong>
                </p>
              </div>
              <div className={style.secondSectionSecondInnerCardContent}>
                <img src="/assets/tick.svg" alt="tick" />

                <p style={{ marginLeft: "1rem", fontFamily: "Lato" }}>
                  <strong>
                    Wir investieren kontinuierlich in die Entwicklung neuer
                    Lösungen, um die Erwartungen unserer Kunden nicht nur zu
                    erfüllen, sondern sie zu übertreffen.
                  </strong>
                </p>
              </div>
            </div>
            <img
              src="/assets/chartssss.jpg"
              alt="data analysis"
              className={style.dataAnalysisImg}
              style={{
                left: "0",
              }}
            />
          </div>
        </div>
       
      </div> */}
    </>
  );
};
export default AboutWKSSection;
