import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import your page components
import Home from "./pages/Home/Home";
import UberUns from "./pages/UberUns/UberUns";
import Dienstleistungen from "./pages/Dienstleistungen/Dienstleistungen";
import Kontakt from "./pages/Kontakt/Kontakt";
import AppNavbar from "../src/components/layout/NNavbar";
import Footer from "./components/layout/Footer";

export default function App() {
  return (
    <Router>
      <AppNavbar />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/uber-uns" element={<UberUns />} />
        <Route path="/dienstleistungen" element={<Dienstleistungen />} />
        <Route path="/kontakt" element={<Kontakt />} />
      </Routes>
      <Footer />
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
