/* eslint-disable */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles from "../../styles/Navbar.module.css";
// import wks from "../../assets/anotherLogo.png";
function AppNavbar() {
  // State to manage dropdown visibility
  const [isFeaturesVisible, setFeaturesVisible] = useState(false);
  const [isHelpVisible, setHelpVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  // Mobile dropdown states
  const [isMobileFeaturesVisible, setMobileFeaturesVisible] = useState(false);
  const [isMobileHelpVisible, setMobileHelpVisible] = useState(false);
  // Function to toggle Features dropdown
  const toggleFeatures = () => setFeaturesVisible(!isFeaturesVisible);

  // Function to toggle Help Center dropdown
  const toggleHelp = () => setHelpVisible(!isHelpVisible);

  // Mobile dropdown toggle functions
  const toggleMobileFeatures = () =>
    setMobileFeaturesVisible(!isMobileFeaturesVisible);
  const toggleMobileHelp = () => setMobileHelpVisible(!isMobileHelpVisible);

  // New state for mobile menu visibility
  const toggleMobileMenu = () => {
    // Close all mobile dropdowns when closing the mobile menu
    if (isMobileMenuVisible) {
      setMobileFeaturesVisible(false);
      setMobileHelpVisible(false);
    }
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleMobileDropdownClick = (event) => {
    toggleMobileMenu(); // This will close the mobile menu
    // You may want to handle navigation here if you're not using Link's default behavior
  };

  const mobileFeaturesClasses = isMobileFeaturesVisible
    ? `${styles.mobileDiv} ${styles.active}`
    : styles.mobileDiv;
  const mobileHelpClasses = isMobileHelpVisible
    ? `${styles.mobileDiv} ${styles.active}`
    : styles.mobileDiv;

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <Link to="/">
          <a className={styles.navbarBrand}>
            <img
              // src="../../assets/wkslogo.png"
              src="/assets/finalLogo.png"
              alt="wks"
              className={styles.navbarBrand}
            />
          </a>
        </Link>
        <div className={styles.navbarLinks}>
          <Link to="/uber-uns">
            <a style={{ color: "white", textDecoration: "none" }}>Über uns </a>
          </Link>

          <Link to="/dienstleistungen">
            <a style={{ color: "white", textDecoration: "none" }}>
              Dienstleistungen
            </a>
          </Link>

          <Link to="/kontakt">
            <a className={styles.kontakt} rel="noopener noreferrer">
              Kontaktiere uns
            </a>
          </Link>
        </div>
        {/* Hamburger icon */}
        <div className={styles.hamburger} onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuVisible && (
          <div className={styles.mobileMenu}>
            {/* Close button */}
            <button className={styles.closeButton} onClick={toggleMobileMenu}>
              &times;
            </button>
            {/* Mobile Links */}
            <Link to="/uber-uns">
              <a onClick={toggleMobileMenu}>Über uns</a>
            </Link>
            {/* Mobile Features Dropdown */}

            {/* <div onClick={toggleMobileFeatures} className={styles.mobileDiv}>
              <Link to="/features">
                <a
                  onClick={toggleMobileMenu}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  Features
                </a>
              </Link>
              {isMobileHelpVisible ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isMobileFeaturesVisible && (
              <div className={styles.mobileDropdownContent}>
                <a
                  to="/features/online-offline-data-collection"
                  onClick={toggleMobileMenu}
                  style={{ color: "#e410d7", textDecoration: "none" }}
                >
                  Online & Offline Data Collection  
                </a>
                <a
                  to="/features/beneficiary-management"
                  onClick={toggleMobileMenu}
                  style={{ color: "#e410d7", textDecoration: "none" }}
                >
                  Beneficiary Management  
                </a>

                <a
                  onClick={toggleMobileMenu}
                  to="/features/taskAllocation-and-management"
                  style={{ color: "#e410d7", textDecoration: "none" }}
                >
                  Task Allocation & Management
                </a>
                <a
                  onClick={toggleMobileMenu}
                  to="/features/streamlined-platform"
                  style={{ color: "#e410d7", textDecoration: "none" }}
                >
                  Streamlined platform
                </a>
                <a
                  onClick={toggleMobileMenu}
                  to="/features/beneficiary-feedback"
                  style={{ color: "#e410d7", textDecoration: "none" }}
                >
                  Beneficiary feedback system
                </a>
                <a
                  onClick={toggleMobileMenu}
                  to="/features/targets-management"
                  style={{ color: "#e410d7", textDecoration: "none" }}
                >
                  Targets Management
                </a>
              </div>
            )} */}
            <Link to="/dienstleistungen">
              <a onClick={toggleMobileMenu}>Dienstleistungen</a>
            </Link>

            <Link to="/kontakt">
              <a onClick={toggleMobileMenu}>Kontaktiere uns</a>
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
}

export default AppNavbar;
