/* eslint-disable */

import React, { useState } from "react";
import style from "../../styles/TransformingDataSection.module.css";
import video from "../../assets/playVideo.png";
const TransformingDataSection = () => {
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideoModal = () => {
    setShowVideo(!showVideo);
  };

  return (
    <div className={style.secondSection}>
      {/* Button to open video overlay */}
      <button className={style.videoButton} onClick={toggleVideoModal}>
        <img src={video} alt="Play Video" /> <span>Schau das Video</span>
      </button>

      {/* Video overlay */}
      {showVideo && (
        <div className={style.videoOverlay} onClick={toggleVideoModal}>
          <div
            className={style.videoWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            <iframe
              className={style.responsiveIframe}
              src="https://www.youtube.com/embed/7LpgkUv1Xgg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <button className={style.closeButton} onClick={toggleVideoModal}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransformingDataSection;
